import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  focus() {
    console.log("Focus event triggered!");

    // Select the element by its ID
    const collapseElement = document.getElementById("filtersCollapse");

    if (collapseElement) {
      console.log("Filters collapse element found:", collapseElement);

      // Use Bootstrap Collapse API to open the collapse section
      const collapseInstance = new bootstrap.Collapse(collapseElement, {
        toggle: false,
      });
      collapseInstance.show();
    } else {
      console.error("Filters collapse element not found!");
    }
  }
}
