import { Controller } from '@hotwired/stimulus';
import toastr from 'toastr';
import * as bootstrap from "bootstrap";
import { Loader } from '@googlemaps/js-api-loader';

export default class extends Controller {
  static targets = ['mapView', 'schoolList', 'funnel', 'toggleViewBtn', 'filtersInterface', 'addressInput', 'schoolData'];
  infoWindow;
  connect() {
    this.toggleViewBtnTarget.addEventListener('click', () => this.toggleMobileView());
    const urlParams = new URLSearchParams(window.location.search);
    const selectedGrade = urlParams.get('gradeFilter');

    // Warn the user if no grade is selected
    if (!selectedGrade) {
      toastr.warning("Please select a grade to view school boundaries.");
    }

    if (!this.map) {
      this.initMap();
      this.updateSchoolMarkers();
      this.adjustMapHeight();
    } else {
      this.updateSchoolMarkers();
      this.adjustMapHeight();
    }

    this.showModalIfNeeded();

    window.addEventListener('resize', () => this.adjustMapHeight());
  }


  showModalIfNeeded() {
    const urlParams = new URLSearchParams(window.location.search);
    const gradeFilter = urlParams.get("gradeFilter");

    // Only show the modal if no grade is selected and it hasn't been shown before
    if (!gradeFilter) {
      const modalShown = localStorage.getItem("gradeFilterModalShown");

      if (!modalShown) {
        // Initialize and show the modal
        const modalElement = document.getElementById("gradeFilterModal");
        const modal = new bootstrap.Modal(modalElement, { backdrop: "static", keyboard: false });
        modal.show();

        // Set a flag in localStorage to indicate the modal has been shown
        localStorage.setItem("gradeFilterModalShown", "true");
      }
    }
  }
  async initMap() {

    const urlParams = new URLSearchParams(window.location.search);
    const schoolSearch = urlParams.get('school_name');

    const loader = new Loader({
      apiKey: "AIzaSyCsQZ4MHVwyL27CHixVi7KyZQoFPcUKj1Y",
      version: "weekly",
      libraries: ["places", "geometry"]
    });
    const google = await loader.load()
    let mapCenter = await this.getMapCenter();
    //home Icon for the map
    const homeIcon = {
      url: 'https://www.clker.com/cliparts/g/9/4/c/Y/0/orange-map-pin.svg.thumb.png', // URL to your home icon image
      scaledSize: new google.maps.Size(32, 42) // Adjust size as needed
    };

    //Map POI styling
    const schoolMapStyle = [{
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{
        visibility: 'off'
      }]
    }]

    this.map = new google.maps.Map(this.mapViewTarget, {
      center: mapCenter,
      zoom: 13,
      gestureHandling: 'greedy',
      style: schoolMapStyle,
    });


    this.infoWindow = new google.maps.InfoWindow()
    this.updateSchoolMarkers(this.map)
    this.initGooglePlacesAutocomplete();
    const schools = JSON.parse(this.schoolDataTarget.dataset.schools);
    if (schoolSearch && schools.length < 5 && schools.length > 0) {
      mapCenter = new google.maps.LatLng(schools[0].school_lat, schools[0].school_lon)
    }
    //this.updateHomeMarker(this.map, mapCenter)


    //get address from params
    const address = urlParams.get('address');
    if (address) {
    this.highlightBoundary(address);
    }


  }

  adjustMapHeight() {
    const navbarHeight = 70; // Adjust this if your navbar height changes
    const windowHeight = window.innerHeight
    const mapHeight = windowHeight - navbarHeight
    this.mapViewTarget.style.height = `${mapHeight}px`
  }
  getMapCenter() {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);
      const userAddress = urlParams.get('address');
      let neighborhoodFilter = urlParams.get('neighborhoodFilter');

      if (neighborhoodFilter) {
        neighborhoodFilter += ', Denver, Colorado';
      }

      const addressToGeocode = userAddress || neighborhoodFilter || '39.7392, -104.9903';

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: addressToGeocode }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          resolve(results[0].geometry.location);
        } else {
          reject('Geocoding failed: ' + status);
        }
      });
    });
  }

	initGooglePlacesAutocomplete() {
		const input = document.getElementById('address-input');
		const form = input.closest('form'); // Get the nearest form ancestor
		const autocomplete = new google.maps.places.Autocomplete(input, {
			types: ['geocode'], // Restrict to geographic locations
			bounds: new google.maps.LatLngBounds(
				new google.maps.LatLng(39.614, -105.227), // Southwestern boundary
				new google.maps.LatLng(40.06, -104.611) // Northeastern boundary
			)
		});

		autocomplete.addListener('place_changed', function() {
			const place = autocomplete.getPlace();
			if (!place.geometry) {
				console.warn("No details available for input: '" + place.name + "'");
				return;
			}
			// Use place details as needed
			form.submit(); // Submit the form when a place is selected
		});
	}




  async updateSchoolMarkers(map) {
    const schools = await this.loadSchoolData();

    // Initialize an array to store the markers
    this.mapMarkers = [];
    const infoWindow = new google.maps.InfoWindow(); // Create a single info window instance

    schools.forEach((school) => {
      const schoolLatLng = new google.maps.LatLng(
        school.school_lat,
        school.school_lon
      );
      const icon = {
        url: 'https://i.imgur.com/nJLO5MD.png', // Default marker icon
        scaledSize: new google.maps.Size(36, 40), // Default icon size
      };
      const marker = new google.maps.Marker({
        position: schoolLatLng,
        map: map,
        title: school.school_no, // Use school_no as the unique identifier
        icon: icon,
      });

      // Add a click listener to the marker
      marker.addListener('click', () => {
        // Show school info in the info window
        const content = `
          <h6>${school.school_name}</h6>
          <p>${school.address_street}, ${school.address_city}, ${
          school.address_state
        } ${school.address_zip}</p>
          <p>Phone: ${school.phone || 'N/A'}</p>
          <p>Principal: ${school.principal_name || 'N/A'}</p>
          <p>Grade Span: ${school.grade_span || 'N/A'}</p>
          <p>Enrollment: ${school.enrollment || 'N/A'}</p>
            <p><a href="/schools/${school.school_no}" class="btn btn-primary btn-sm">View Details</a></p>
        `;
        infoWindow.setContent(content);
        infoWindow.open(map, marker);

        // Scroll to and highlight the corresponding school card
        this.scrollToSchoolCard(school.school_no);

        // Highlight the marker
        this.highlightMarker(marker);
      });

      // Store the marker in the array
      this.mapMarkers.push(marker);
    });
  }

  scrollToSchoolCard(schoolId, labelName = null) {
    // Remove existing highlights and labels from all school cards
    document.querySelectorAll('.school-card').forEach((card) => {
      card.classList.remove('highlighted'); // Remove the highlight class
      const labelElement = card.querySelector('.dynamic-label');
      if (labelElement) {
        labelElement.remove(); // Remove the dynamic label
      }
    });

    // Find the corresponding school card
    const schoolCard = document.querySelector(`#school-card-${schoolId}`);
    if (schoolCard) {
      schoolCard.classList.add('highlighted'); // Add the highlight class

      // Add or update the dynamic label if provided
      if (labelName) {
        let labelElement = schoolCard.querySelector('.dynamic-label');
        if (!labelElement) {
          // Create a new label element if it doesn't exist
          labelElement = document.createElement('span');
          labelElement.classList.add('dynamic-label');
          schoolCard.querySelector('.school-card-title').appendChild(labelElement); // Append to the card title
        }
        labelElement.textContent = `${labelName}`; // Set the label text
      }

      // Scroll the school list only (not the entire page)
      const schoolListContainer = this.schoolListTarget;
      if (schoolListContainer) {
        const cardOffset = schoolCard.offsetTop - schoolListContainer.offsetTop;
        schoolListContainer.scrollTo({
          top: cardOffset,
          behavior: 'smooth', // Smooth scrolling
        });
      }
    }
  }

  addLabelToSchoolCard(schoolId, labelName) {
    // Find the corresponding school card
    const schoolCard = document.querySelector(`#school-card-${schoolId}`);
    if (schoolCard) {
      // Check if a label row already exists, and create one if it doesn't
      let labelRow = schoolCard.querySelector('.dynamic-label-row');
      if (!labelRow) {
        // Create a new row for the dynamic label
        labelRow = document.createElement('div');
        labelRow.classList.add('dynamic-label-row'); // Add a class for styling
        schoolCard.appendChild(labelRow); // Append the new row to the school card
      }

      // Set the label text in the new row
      labelRow.textContent = labelName;
    }
  }



  highlightMarker(selectedMarker) {
    // Reset all markers to their default icon
    this.mapMarkers.forEach((marker) => {
      marker.setIcon({
        url: 'https://i.imgur.com/nJLO5MD.png', // Default marker icon
        scaledSize: new google.maps.Size(36, 40), // Default icon size
      });
    });

    // Highlight the selected marker
    selectedMarker.setIcon({
      url: 'https://i.imgur.com/Mxb4QyW.png', // Highlighted marker icon
      scaledSize: new google.maps.Size(48, 52), // Highlighted icon size
    });
  }




  async highlightBoundary(address) {
    try {
      // Get the grade from the URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const selectedGrade = urlParams.get('gradeFilter');

      // Do not proceed if no grade is selected
      if (!selectedGrade) {
        console.warn("Grade is not selected. Skipping boundary check.");
        toastr.warning("Please select a grade to view boundaries.");
        return;
      }

      // Geocode the user's address to get the coordinates
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
          const userLocation = results[0].geometry.location;

          // Add or update the marker for the user's address
          if (!this.addressMarker) {
            this.addressMarker = new google.maps.Marker({
              position: userLocation,
              map: this.map,
              title: 'Your Location',
            });
          } else {
            this.addressMarker.setPosition(userLocation);
          }

          // Slightly zoom and center the map on the user's location
          this.map.setCenter(userLocation);
          this.map.setZoom(14); // Adjust zoom level as needed
        } else {
          console.warn("Failed to geocode address:", status);
          toastr.warning("Unable to locate the address. Please try again.");
          return;
        }
      });

      // Fetch the matching boundary from the Rails API with the grade parameter
      const response = await fetch(
        `/check_boundary?address=${encodeURIComponent(address)}&grade=${encodeURIComponent(selectedGrade)}`
      );
      const data = await response.json();

      // Check if a matching boundary was found
      if (data.type === "Feature") {
        // Add the boundary to the map
        this.map.data.addGeoJson(data);

        // Style the boundary
        this.map.data.setStyle({
          fillColor: 'lightblue',
          strokeColor: 'blue',
          strokeWeight: 2,
          fillOpacity: 0.3,
        });

        // Handle the label and highlight schools in the boundary
        const labelName = data.properties.label_name || "Neighborhood School";
        if (labelName === "Enrollment Zone School") {
          this.highlightMarkersInBoundary(data, labelName);
        } else {
          const schoolNumber = data.properties.school_number;
          if (schoolNumber) {
            this.addLabelToSchoolCard(schoolNumber, labelName);
            const marker = this.mapMarkers.find((m) => m.title === schoolNumber.toString());
            if (marker) this.highlightMarker(marker);
          }
        }
      } else {
        toastr.info(data.message || "Address is not within any boundary.");
      }
    } catch (error) {
      console.error("Error highlighting boundary:", error);
      toastr.error("Failed to process boundary. Please try again.");
    }
  }


  highlightMarkersInBoundary(boundary, labelName) {
    // Convert GeoJSON boundary into Google Maps LatLng objects
    const boundaryCoordinates = boundary.geometry.coordinates[0][0].map(
      (coord) => new google.maps.LatLng(coord[1], coord[0])
    );

    // Create a polygon from the boundary
    const boundaryPolygon = new google.maps.Polygon({ paths: boundaryCoordinates });

    // Loop through all schools and apply labels to those within the boundary
    const schools = JSON.parse(this.schoolDataTarget.dataset.schools);
    schools.forEach((school) => {
      const schoolLatLng = new google.maps.LatLng(school.school_lat, school.school_lon);

      // Check if the school is inside the boundary
      if (google.maps.geometry.poly.containsLocation(schoolLatLng, boundaryPolygon)) {
        // Find the corresponding marker and highlight it
        const marker = this.mapMarkers.find((m) => m.title === school.school_no.toString());
        if (marker) {
          marker.setIcon({
            url: 'https://i.imgur.com/Mxb4QyW.png', // Highlighted icon
            scaledSize: new google.maps.Size(48, 52),
          });
        }

        // Add or update the label for the school card
        this.addLabelToSchoolCard(school.school_no, labelName);
      }
    });
  }


  async loadSchoolData() {
		// Get the filter values from the checkboxes / select boxes
		const urlParams = new URLSearchParams(window.location.search)
		const gradeFilter = urlParams.get('gradeFilter')
		const neighborhoodFilter = urlParams.get('neighborhoodFilter')
    const schoolSearch = urlParams.get('school_name')
		// Set the select element's value to the retrieved value (if it exists)
		if (gradeFilter) {
			document.getElementById('gradeFilter').value = gradeFilter
		}

		let url = `/schools/data?`

		if (gradeFilter) {
			url += `gradeFilter=${gradeFilter}`
		}

		if (neighborhoodFilter) {
			url += `&neighborhoodFilter=${neighborhoodFilter}` // Add neighborhood filter to the URL
		}

    if (schoolSearch) {
      url += `&school_name=${schoolSearch}`
    }

		return fetch(url)
			.then(response => response.json())
			.then(data => {
				this.schoolDataTarget.dataset.schools = JSON.stringify(data)
        return JSON.parse(this.schoolDataTarget.dataset.schools)
			})
			.catch(error => {
				console.error('Error loading school data:', error)
			})
	}

	isFavoritesRoute() {
		return window.location.pathname.includes('/favorites')
	}

  filtersdisplay() {
		const filtersInterface = this.filtersInterfaceTarget
		console.log(filtersInterface.style.width)
		if (
			!filtersInterface.style.width ||
			filtersInterface.style.width === '0%'
		) {
			filtersInterface.style.display = 'block'
			if (window.innerWidth <= 768) {
				filtersInterface.style.width = '100%'
			} else {
				filtersInterface.style.width = '50%'
			}
		} else {
			filtersInterface.style.display = 'none'
			filtersInterface.style.width = '0%'
		}
	}

	toggleFilters() {
		const filtersCollapse = document.getElementById('filtersCollapse')
		if (filtersCollapse) {
			if (filtersCollapse.classList.contains('show')) {
				filtersCollapse.classList.remove('show')
			} else {
				filtersCollapse.classList.add('show')
			}
		}
	}

  toggleMobileView() {
    if (this.mapViewTarget.style.display === 'none') {
      // Show the map and hide the list view
      this.mapViewTarget.style.display = 'block';
      this.schoolListTarget.style.display = 'none';
      this.toggleViewBtnTarget.innerHTML = '<i class="fa-solid fa-magnifying-glass"></i>';
    } else {
      // Show the list view and hide the map
      this.mapViewTarget.style.display = 'none';
      this.schoolListTarget.style.display = 'block';
      this.toggleViewBtnTarget.innerHTML = '<i class="fa-solid fa-map-location-dot"></i>';
    }
  }
}
