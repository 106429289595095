import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="transport"
export default class extends Controller {
  static targets = [
    "loading",
    "schoolNo",
    "schooladdress",
    "summary",
    "details",
    "totalWalking",
    "busRoutes",
    "totalDuration",
  ];

  showTransport(event) {
    const schoolId = event.currentTarget.dataset.schoolId;
    const modal = document.querySelector(`#transportModal-${schoolId}`);
    const loadingElement = modal.querySelector(
      '[data-transport-target="loading"]'
    );
    const summaryElement = modal.querySelector(
      '[data-transport-target="summary"]'
    );
    const detailsElement = modal.querySelector(
      '[data-transport-target="details"]'
    );
    const address = new URLSearchParams(window.location.search).get("address");
    const schoolAddress = modal.querySelector(
      '[data-transport-target="schooladdress"]'
    ).value;

    // Show loading spinner
    loadingElement.style.display = "block";
    summaryElement.classList.add("d-none");
    detailsElement.classList.add("d-none");

    // Fetch transportation details
    fetch(
      `/directions?origin=${encodeURIComponent(
        address
      )}&destination=${encodeURIComponent(schoolAddress)}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Hide loading spinner
        loadingElement.style.display = "none";

        // Check if legs exist
        if (!data.legs || data.legs.length === 0) {
          summaryElement.innerHTML = `<i class="fas fa-exclamation-circle text-warning"></i> You didn't set an address! Please set your address in the search bar above.`;
          summaryElement.classList.remove("d-none");
          return;
        }

        // Update Summary Section
        // Update Summary Section
        const totalWalking =
          data.legs
            .filter((leg) => leg.travel_mode === "WALKING")
            .reduce((sum, leg) => {
              const distanceText = leg.distance || "0 mi";
              const distanceValue = parseFloat(distanceText);
              const unit = distanceText.includes("mi") ? "mi" : "ft";
              const distanceInMiles =
                unit === "ft" ? distanceValue / 5280 : distanceValue;
              return sum + distanceInMiles;
            }, 0)
            .toFixed(1) + " mi";
            const busRoutes = data.legs
            .filter((leg) => leg.travel_mode === "TRANSIT")
            .map((leg) => leg.bus_details?.route || "Unknown route")
            .join(", ");

        const walkingDuration = data.legs
          .filter((leg) => leg.travel_mode === "WALKING")
          .reduce((sum, leg) => {
            const walkingDuration = leg.duration
              ? parseInt(leg.duration.replace(" mins", ""))
              : 0;
            return sum + walkingDuration;
          }, 0);

        const busDuration = data.legs
          .filter((leg) => leg.travel_mode === "TRANSIT")
          .reduce((sum, leg) => {
            const busDetails = leg.bus_details;
            const transitDuration = busDetails?.duration
              ? parseInt(busDetails.duration.replace(" mins", ""))
              : 0;
            return sum + transitDuration;
          }, 0);

        const totalDuration = `${
          walkingDuration + busDuration
        } mins (${walkingDuration} walking, ${busDuration} by bus)`;

        modal.querySelector(
          '[data-transport-target="total-duration"]'
        ).innerText = totalDuration;

        modal.querySelector(
          '[data-transport-target="total-walking"]'
        ).innerText = totalWalking;

        modal.querySelector('[data-transport-target="bus-routes"]').innerText =
          busRoutes || "None";

        summaryElement.classList.remove("d-none");

        // Update Detailed Steps Section
        const detailsHtml = data.legs
          .map((leg, index) => {
            if (leg.travel_mode === "WALKING") {
              return `
                <div class="timeline-step d-flex align-items-start mb-4">
                  <div class="icon-container bg-light-primary text-primary rounded-circle d-flex justify-content-center align-items-center me-3" style="width: 50px; height: 50px;">
                    <i class="fas fa-walking"></i>
                  </div>
                  <div>
                    <h6 class="fw-semibold text-dark mb-1">Step ${
                      index + 1
                    }: Walk</h6>
                    <p class="mb-1 text-muted small">${
                      leg.instructions || "No instructions available"
                    }</p>
                    <p class="mb-0 text-muted small">
                      <i class="fas fa-route me-1"></i> ${
                        leg.distance || "N/A"
                      } |
                      <i class="fas fa-clock me-1"></i> ${leg.duration || "N/A"}
                    </p>
                  </div>
                </div>`;
            } else if (leg.travel_mode === "TRANSIT") {
              const bus = leg.bus_details;
              return `
                <div class="timeline-step d-flex align-items-start mb-4">
                  <div class="icon-container bg-light-success text-success rounded-circle d-flex justify-content-center align-items-center me-3" style="width: 50px; height: 50px;">
                    <i class="fas fa-bus"></i>
                  </div>
                  <div>
                    <h6 class="fw-semibold text-dark mb-1">Step ${
                      index + 1
                    }: Bus Route ${bus.route || "N/A"}</h6>
                    <div class="mb-1 small text-muted">
                      <span class="d-inline-flex align-items-center">
                        <i class="fas fa-map-marker-alt me-1"></i> From: ${
                          bus.departure_stop || "N/A"
                        }
                      </span>
                    </div>
                    <div class="mb-1 small text-muted">
                      <span class="d-inline-flex align-items-center">
                        <i class="fas fa-flag-checkered me-1"></i> To: ${
                          bus.arrival_stop || "N/A"
                        }
                      </span>
                    </div>
                    <p class="mb-0 text-muted small">
                      <i class="fas fa-clock me-1"></i> Duration: ${
                        bus.duration || "N/A"
                      } |
                      <i class="fas fa-map-marker-alt me-1"></i> Stops: ${
                        bus.number_of_stops || "N/A"
                      }
                    </p>
                  </div>
                </div>`;
            }
          })
          .join("");

        detailsElement.innerHTML = detailsHtml;
        detailsElement.classList.remove("d-none");
      })
      .catch((error) => {
        console.error("Error fetching transport details:", error);
        loadingElement.innerHTML = `<i class="fas fa-exclamation-triangle text-danger"></i> Failed to load transport details.`;
      });
  }
}
