// app/javascript/controllers/toggle_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["compareBar", "openButton"]

  connect() {
    if (window.innerWidth <= 768) {
      this.close();
    }
  }

  close() {
    this.compareBarTarget.style.display = 'none'
    this.openButtonTarget.classList.remove('d-none')
  }

  open() {
    this.compareBarTarget.style.display = 'block'
    this.openButtonTarget.classList.add('d-none') // Hide the 'Comparison Menu' button
  }
}
