import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="walkscore"
export default class extends Controller {
  static targets = ["button", "tooltip"];

  connect() {
    this.tooltipVisible = false; // Track tooltip visibility
  }

  show(event) {
    const schoolId = event.currentTarget.dataset.walkscoreSchoolid;
    this.buttonTarget.disabled = true; // Disable the button
    this.buttonTarget.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Loading'; // Show loading spinner

    // Fetch the Walk Score
    fetch(`/schools/${schoolId}/walkscore`)
      .then((response) => response.json())
      .then((data) => {
        const walkscore = data.walkscore;
        const address = data.address;

        // Construct the Walk Score link
        const walkscoreLink = address
          ? `https://www.walkscore.com/score/${encodeURIComponent(address)}`
          : `https://www.walkscore.com/`;

        // Update the button with the Walk Score and link
        this.buttonTarget.innerHTML = `
          <a href="${walkscoreLink}" target="_blank" class="text-decoration-none text-light">
            Walkscore: ${walkscore} <i class="fas fa-external-link-alt"></i>
          </a>
        `;

        this.buttonTarget.disabled = false; // Re-enable the button
        this.toggleTooltip(); // Show the tooltip
      })
      .catch((error) => {
        console.error("Error fetching walkscore:", error);
        this.buttonTarget.innerHTML = 'Error fetching walkscore';
        this.buttonTarget.disabled = false; // Re-enable the button
        this.toggleTooltip(); // Show the tooltip even on error
      });
  }

  toggleTooltip() {
    if (this.tooltipVisible) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  }

  showTooltip() {
    this.tooltipTarget.classList.add("visible");
    this.tooltipVisible = true;

    // Dynamically apply the max-width via JavaScript to override inline styles
    this.tooltipTarget.style.maxWidth = "500px";
    this.tooltipTarget.style.width = "auto";

    // Adjust position to ensure it stays in view
    this.adjustTooltipPosition();
  }


  hideTooltip() {
    this.tooltipTarget.classList.remove("visible");
    this.tooltipVisible = false;
  }

  adjustTooltipPosition() {
    const tooltipRect = this.tooltipTarget.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Reset styles to default before recalculating
    this.tooltipTarget.style.left = "50%";
    this.tooltipTarget.style.top = "100%";
    this.tooltipTarget.style.transform = "translateX(-50%)";

    // Adjust horizontal position if the tooltip overflows the left or right edge
    if (tooltipRect.left < 0) {
      this.tooltipTarget.style.left = "10px"; // Align with a small margin from the left
      this.tooltipTarget.style.transform = "translateX(0)";
    } else if (tooltipRect.right > viewportWidth) {
      this.tooltipTarget.style.left = `${viewportWidth - tooltipRect.width - 10}px`; // Align with a small margin from the right
      this.tooltipTarget.style.transform = "translateX(0)";
    }

    // Adjust vertical position if the tooltip overflows the bottom
    if (tooltipRect.bottom > viewportHeight) {
      this.tooltipTarget.style.top = "auto";
      this.tooltipTarget.style.bottom = "100%";
      this.tooltipTarget.style.transform = "translateX(-50%)";
    }
  }

}
